<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Rooms Management</h3>
            <hr class="m-t-0 m-b-40">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Nama OPD</label>
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.data_opd"
                      id="selectBox"
                      name="selectBox"
                    >
                      <option value>-- Pilih --</option>
                      <option value="1">Gubernur</option>
                      <option value="2">Wakil Gubernur</option>
                      <option value="3">Sekretariat Daerah</option>
                      <option value="4">Kepala Dinas</option>
                      <option value="5">Wakil Kepala Dinas</option>
                      <option value="6">Sekretaris</option>
                      <option value="7">Kepala Bidang</option>
                      <option value="8">Kepala SubBagian</option>
                      <option value="9">Kepala Seksi</option>
                      <option value="10">Staff</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.name" name="nama" required type="text" label="Nama"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.phone"
                  name="nama"
                  required
                  type="text"
                  label="Telp Pengelola Ruang"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Alamat</label>
                  <div class="col-md-9 col-xs-9">
                    <textarea v-model="payload.address" class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.capacity"
                  name="nama"
                  required
                  type="text"
                  label="Kapasitas (Orang)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Fasilitas</label>
                  <div class="col-md-3 col-sm-12">
                    <div class="checkbox" v-for="(value, index) in refFacilitites" :key="index">
                      <input
                        v-model="payload.facilities"
                        :value="value.id"
                        :id="'checkbox'+value.id"
                        type="checkbox"
                      >
                      <label :for="'checkbox'+value.id">{{value.name}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">Status</label>
                  <div :class="['col-md-9']">
                    <select
                      class="form-control custom-select"
                      v-model="payload.status"
                      id="selectBox"
                      name="selectBox"
                    >
                      <option value>-- Pilih --</option>
                      <option value="1">Aktif</option>
                      <option value="2">Non Aktif</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.rooms;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidUnit() {
      return this.isTouched && this.payload.valueUnit.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        data_opd: "",
        phone: "",
        name: "",
        address: "",
        capacity: "",
        status: "",
        facilities: []
      },
      refFacilitites: [],
      isTouched: false,
      isDisabled: false
    };
  },
  async mounted() {
    this.getFasilities();
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/rooms/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("rooms/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("rooms/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        data_opd: data.data_opd,
        phone: data.phone,
        name: data.name,
        address: data.address,
        capacity: data.capacity,
        status: data.status,
        facilities: data.facilities
      };
      this.refFacilitites = data.facilities;
    },
    onTouch() {
      this.isTouched = true;
    },
    getFasilities() {
      axios
        .get(`/facilities`, {
          params: {
            search: "",
            page: "1",
            limit: "100"
          }
        })
        .then(res => {
          this.refFacilitites = res.data.items;
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeUnit(value) {
      this.payload.valueUnit = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueUnit = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("rooms/onCancel");
    },
    submit() {
      const payload = {
        data_opd: this.payload.data_opd,
        phone: this.payload.phone,
        name: this.payload.name,
        address: this.payload.address,
        capacity: this.payload.capacity,
        status: this.payload.status,
        facilities: this.payload.facilities
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("rooms/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("rooms/submitAdd", data);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

